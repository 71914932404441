/* eslint no-console: ["error", { allow: ["warn", "error"] }] */
import iframeResize from 'iframe-resizer/js/iframeResizer';
import { parse, stringify } from 'query-string';

const DEFAULT_ORIGIN = 'https://www.voteamerica.org';
const VALID_ORIGINS = [
  'https://www.voteamerica.com',
  'https://staging.voteamerica.com',
  'https://www.voteamerica.org',
  'https://staging.voteamerica.org',
  'http://localhost:8000/',
];

const DEFAULT_FRAME_HEIGHT = 608;
const TOOLS = [
  'absentee',
  'futurevoter',
  'leo',
  'locate',
  'pav',
  'pledge',
  'register',
  'remind',
  'upcoming',
  'verify',
  'votebymail',
];

const DEFAULT_EDITION = 'standard';
const VALID_EDITIONS = ['college', 'standard'];

function randomId() {
  return Math.random().toString(36).substring(2, 15);
}

function includes(array, item) {
  return array.indexOf(item) > -1;
}

/**
 *
 * @param {Element} el
 */
function generateUrl(el, id) {
  const qs = parse(document.location.search);
  const {
    overrideSource,
    defaultSource,
    overrideUtmCampaign,
    defaultUtmCampaign,
    overrideUtmSource,
    defaultUtmSource,
    overrideUtmMedium,
    defaultUtmMedium,
    overrideUtmTerm,
    defaultUtmTerm,
    overrideUtmContent,
    defaultUtmContent,
    subscriber,
    tool,
    origin,
    edition,
  } = el.dataset;
  let source;
  let utmCampaign;
  let utmSource;
  let utmMedium;
  let utmTerm;
  let utmContent;
  let websiteOrigin;
  let toolEdition;
  if (overrideSource) {
    source = overrideSource;
  } else if (qs.source) {
    source = qs.source;
  } else {
    source = defaultSource;
  }

  if (overrideUtmCampaign) {
    utmCampaign = overrideUtmCampaign;
  } else if (qs.utm_campaign) {
    utmCampaign = qs.utm_campaign;
  } else {
    utmCampaign = defaultUtmCampaign;
  }

  if (overrideUtmSource) {
    utmSource = overrideUtmSource;
  } else if (qs.utm_source) {
    utmSource = qs.utm_source;
  } else {
    utmSource = defaultUtmSource;
  }

  if (overrideUtmMedium) {
    utmMedium = overrideUtmMedium;
  } else if (qs.utm_medium) {
    utmMedium = qs.utm_medium;
  } else {
    utmMedium = defaultUtmMedium;
  }

  if (overrideUtmTerm) {
    utmTerm = overrideUtmTerm;
  } else if (qs.utm_term) {
    utmTerm = qs.utm_term;
  } else {
    utmTerm = defaultUtmTerm;
  }

  if (overrideUtmContent) {
    utmContent = overrideUtmContent;
  } else if (qs.utm_content) {
    utmContent = qs.utm_content;
  } else {
    utmContent = defaultUtmContent;
  }

  if (origin) {
    websiteOrigin = origin;
  } else {
    websiteOrigin = DEFAULT_ORIGIN;
  }

  if (edition) {
    toolEdition = edition;
  } else {
    toolEdition = DEFAULT_EDITION;
  }

  const query = stringify({
    subscriber,
    source,
    edition: toolEdition,
    utm_campaign: utmCampaign,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_term: utmTerm,
    utm_content: utmContent,
    embed_id: id,
    embed_page_url: window.location.href || '',
    title: qs.title,
    first_name: qs.first_name,
    last_name: qs.last_name,
    suffix: qs.suffix,
    address1: qs.address1,
    address2: qs.address2,
    city: qs.city,
    state: qs.state,
    zipcode: qs.zipcode,
    month_of_birth: qs.month_of_birth,
    day_of_birth: qs.day_of_birth,
    year_of_birth: qs.year_of_birth,
    email: qs.email,
    phone: qs.phone,
    email_referrer: qs.email_referrer,
    mobile_message_referrer: qs.mobile_message_referrer,
  });
  return `${websiteOrigin}/embed/${tool}/?${query}`;
}

/**
 * @param {Element} el
 * @param {object} attrs
 */
function setAttributes(el, attrs) {
  Object.keys(attrs).forEach((key) => el.setAttribute(key, attrs[key]));
}

/**
 * @param {string} url
 */
function generateFrame(url, onMessage) {
  const newFrame = document.createElement('iframe');
  setAttributes(newFrame, {
    src: url,
    style: 'width: 1px;min-width: 100%;',
    frameborder: 0,
    marginheight: 0,
    scrolling: 'no',
    'data-is-voteamerica-iframe': '',
    allow: 'geolocation; microphone; camera',
  });
  iframeResize(
    {
      minHeight: DEFAULT_FRAME_HEIGHT,
      inPageLinks: true,
      checkOrigin: false,
      heightCalculationMethod: 'taggedElement',
      bodyMargin: '0 0',
      onMessage,
    },
    newFrame,
  );
  return newFrame;
}

/**
 * @param {Element} el
 */
export function processEmbed(el) {
  if (el.classList.contains('voteamerica-embed-loaded')) {
    return () => {};
  }
  const {
    tool, subscriber, origin, edition,
  } = el.dataset;

  if (subscriber === undefined) {
    const message = "VoteAmerica Embed Error: Missing proper 'subscriber' attribute";
    el.appendChild(document.createTextNode(message));
    console.error(message);
    return () => {};
  }

  if (tool === undefined || !includes(TOOLS, tool)) {
    const message = "VoteAmerica Embed Error: Missing proper 'tool' attribute.";
    el.appendChild(document.createTextNode(message));
    console.error(message);
    return () => {};
  }

  if (
    origin
    && !includes(VALID_ORIGINS, origin)
    && process.env.NODE_ENV !== 'development'
  ) {
    const message = "VoteAmerica Embed Error: Invalid 'origin' attribute";
    el.appendChild(document.createTextNode(message));
    console.error(message);
    return () => {};
  }

  if (edition && !includes(VALID_EDITIONS, edition)) {
    const message = "VoteAmerica Embed Error: Invalid 'edition' attribute";
    el.appendChild(document.createTextNode(message));
    console.error(message);
    return () => {};
  }

  const id = randomId();

  const frame = generateFrame(generateUrl(el, id), (evt) => {
    if (!evt.message || !evt.message.type) {
      return;
    }
    if (evt.message.type === 'scrollToTop') {
      el.scrollIntoView(true);
    } else if (evt.message.type === 'parentMessage') {
      // use createEvent() rather than new CustomEvent() because the constructor
      // form isn't supported on IE11 (and isn't polyfilled by CoreJS:
      // https://github.com/zloirock/core-js/issues/354)
      const vaEvt = document.createEvent('CustomEvent');
      vaEvt.initCustomEvent(
        'VoteAmericaEvent',
        true /* bubbles */,
        false /* cancellable */,
        {
          data: evt.message.payload,
          iFrame: frame,
          embedEl: el,
        },
      );

      el.dispatchEvent(vaEvt);
    }
  });

  el.appendChild(frame);
  el.classList.add('voteamerica-embed-loaded');

  return () => frame.iFrameResizer.removeListeners();
}

export function processAllEmbeds() {
  const embeds = document.getElementsByClassName('voteamerica-embed');
  const returnFns = [];

  for (let i = 0; i < embeds.length; i += 1) {
    returnFns.push(processEmbed(embeds[i]));
  }

  return () => {
    returnFns.forEach((f) => f());
  };
}

// Run immediately (if this script was loaded async or at the end of the body)
processAllEmbeds();

// Run on DOMContentLoaded
window.addEventListener('DOMContentLoaded', processAllEmbeds);
